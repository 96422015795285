<script setup lang="ts">
import { Times } from '@ui/icons/regular';
import IconButton from '../icons/IconButton.vue';
import Background from '../background/Background.vue';

withDefaults(defineProps<{
  label?: string;
  text?: string;
  close?: boolean;
}>(), {
  label: '',
  text: '',
  close: true,
})

defineSlots<{
  header: () => void;
  content: () => void;
}>();

const show = defineModel<boolean>('show', { default: false });
</script>

<template lang="pug">
  Background(v-model:show="show")
    .modal-dialog.h-100p.flex.col-0
      .header-container.x-paddings.typ-14-20-500.flex.col-4.p-t-20(:class="{grey: $slots['header-bottom']}")
        .header.flex-between-center
          .header-label
            slot(name="header")
              | {{ $t(label) }}
          IconButton.size-20(v-if="close" :name="Times" @click="$emit('update:show', false)")
        .h-20

      .content-container.x-paddings.p-y-12
        slot(name="content")
          | {{ text }}
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.modal-dialog {
  overflow: hidden;
  border: 1px solid #E5E5E9;
  background: $color-white;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.20);

  border-radius: 24px 24px 0 0;
  height: calc(100dvh - 70px);
  margin-top: 70px;
  min-width: 320px;
  width: 100dvw;
  max-width: 800px;

  @screen sm {
    height: calc(100dvh - 100px);
    margin-top: 100px;
  }

  @screen md {
    --uno: br-24;
    height: unset;
    max-height: calc(100dvh - 100px);
    margin-top: unset;
  }
}

.header-container {
  background: #F8F8F9;
}

.x-paddings {
  --uno: p-x-16;

  @screen md {
    --uno: p-x-24;
  }
}

.content-container {
  overflow: auto;
}
</style>